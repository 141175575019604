import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GoAAppHeader, GoAAppFooter, GoAOneColumnLayout, GoAPageBlock, GoAButton, GoAButtonGroup, GoAModal } from "@abgov/react-components";
import { Nav } from "./components/Nav";
import { Home } from "./pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
//import { MyAccount } from "./pages/MyAccount";
import { LoggedOut } from "./pages/LoggedOut";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";


const queryClient = new QueryClient();

function App() {
  const auth = useAuth();
  const [tokenExpiringDlg,setTokenExpiringDlg]  = useState(false); 
  
  
  const logoutTimer= useRef(0);
  useEffect(() => {
    const isLoggedOutPath = window.location.pathname === "/logged-out";
    if (!isLoggedOutPath && !auth.isLoading && !auth.isAuthenticated) {
      if (window.location.search) {
        window.location.href = window.location.origin;
      } else {
        auth.clearStaleState();
        auth.signinRedirect();
      }
    } 
  }, [auth]);

  // useEffect(() => {
  //   // the `return` is important - addAccessTokenExpiring() returns a cleanup function
  //   if(tokenExpiringDlg === false){
  //     return auth.events.addAccessTokenExpiring(() => {
  //       if(logoutTimer.current === 0){
  //         setTokenExpiringDlg(true);
  //         logoutTimer.current = window.setTimeout(() => {
  //           console.log("auto logging out In " +auth.user?.expires_in);
  //           onLogOut();
  //           }, (60000*2) /*2 minutes*/);
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [auth.events, auth.signinSilent]);

  function onLogOut(){
    auth.signoutRedirect({ id_token_hint: auth.user?.id_token }).then( res =>{
        alert(res);
      }
    );
    // close the dialog
    setTokenExpiringDlg(false);
    window.clearTimeout(logoutTimer.current);
    logoutTimer.current = 0;
    //auth.events.removeAccessTokenExpiring(()=>{});
  }
  /**************************************************************************
   * 
  ***************************************************************************/
  function onExtendSession(){
    //auth.startSilentRenew(); // this is to enable automatic silinetRenew..but what we need here is manual renew we trigger the refresh using (signinSilent)
    auth.signinSilent();
    setTokenExpiringDlg(false);
    window.clearTimeout(logoutTimer.current);
    logoutTimer.current = 0;
    //auth.events.removeAccessTokenExpiring(()=>{});
  }
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <GoAOneColumnLayout>
          <section slot="header">
            {/* <GoAMicrositeHeader type="alpha" version={ process.env.NODE_ENV } /> */}
            <GoAAppHeader
              url="/"
              heading="Wildfire Applications Portal"
              maxContentWidth="100%"
            >
              {auth.isAuthenticated && 
                <Nav />
              }
            </GoAAppHeader>
          </section>
          <GoAPageBlock width="1000px">
            <Routes>
              <Route path='/' element={<Home />} />
              {/* <Route path='/my-account' element={<MyAccount />} /> */}
              <Route path='/logged-out' element={<LoggedOut />} />
            </Routes>
          </GoAPageBlock>
          <section slot="footer">
            <GoAAppFooter />
          </section>
        </GoAOneColumnLayout>
      </BrowserRouter>
      <GoAModal
        heading="Logout" open={tokenExpiringDlg}
        calloutVariant="emergency"
        // role="alertdialog"
        //onClose={() => onLogOut()}
        actions={
          <GoAButtonGroup alignment="end" mt="l">
            <GoAButton type="secondary" onClick={onLogOut}>Log out</GoAButton>
            <GoAButton type="primary" onClick={onExtendSession}>Extend my session</GoAButton>
          </GoAButtonGroup>
        }
      >
      <p>You will be logged out within 2 minutes!</p>
      </GoAModal>
    </QueryClientProvider>
  );
}

export default App;