const envUrls: EnvUrl = {
  dev: {
    flightReports: "https://wmtt-aviation-reporting-tst.azurewebsites.net",
    wildfireAdmin: "https://admin.wildfireapps-dev.alberta.ca",
    finance: "https://contracts.wildfireapps-dev.alberta.ca",
    warehouseInventory: "https://contracts.wildfireapps-dev.alberta.ca",
    imis: "https://goa-cc-wmis-app-imis-dev.azurewebsites.net"
           
  },
  uat: {
    flightReports: "https://aviation.wildfireapps-uat.alberta.ca",
    wildfireAdmin: "https://admin.wildfireapps-uat.alberta.ca",
    finance: "https://contracts.wildfireapps-uat.alberta.ca",
    warehouseInventory: "https://contracts.wildfireapps-dev.alberta.ca",
    imis: "https://imis.wildfireapps-uat.alberta.ca"
  },
  prod: {
    flightReports: "https://aviation.wildfireapps.alberta.ca",
    wildfireAdmin: "https://admin.wildfireapps.alberta.ca",
    finance: "https://contracts.wildfireapps.alberta.ca",
    warehouseInventory: "https://contracts.wildfireapps-dev.alberta.ca",
    imis: "https://imis.wildfireapps.alberta.ca"
  },
};

interface EnvUrl {
  dev: UrlList;
  uat: UrlList;
  prod: UrlList;
}

interface UrlList {
  flightReports: string;
  wildfireAdmin: string;
  finance: string;
  warehouseInventory: string;
  imis: string;
}

const appEnv = process.env.REACT_APP_ENV as keyof EnvUrl;

export const appList: AppConfig[] = [
  {
    url: envUrls[appEnv].flightReports,
    resource: "FlightReports",
    permission: "p_Aviat_FlightRpt_R",
    label: "Aviation",
    toolTipText: "Placeholder text",
    enableLink: true,
    img:"helicopter.png"
  },
  {
    url: envUrls[appEnv].wildfireAdmin,
    resource: "wildfire-admin-api",
    permission: "Adm_UserList_V",
    label: "Application Administration",
    toolTipText: "Placeholder text",
    enableLink: true,
    img:"checklist.png"
  },
  {
    url: envUrls[appEnv].finance,
    resource: "finance",
    permission: "Fin_Invoice_V",
    label: "Finance",
    toolTipText: "Placeholder text",
    enableLink: true,
    img:"financialReport.png"
  },
  {
    url: envUrls[appEnv].imis,
    resource: "imis_client",
    permission: "IMS_WO_V",
    label: "Warehouse Management",
    toolTipText: "Placeholder text",
    enableLink: true,
    img:"warehouse.png"
  },
  // {
  //   url: envUrls[appEnv].flightReports,
  //   resource: "wildfire-admin-api",
  //   permission: "Adm_UserList_V",
  //   label: "Warehouse Inventory",
  //   toolTipText: "Placeholder text",
  //   enableLink: false,
  //   img:"homeStorage.png"
  // },
  
];

class AppConfig {
  url?: string;
  resource?: string;
  permission?: string;
  label?: string;
  toolTipText?: string;
  enableLink?: boolean;
  img?:string;
}
