import './Nav.css'
import { GoAAppHeaderMenu } from "@abgov/react-components";
import { useAuth } from 'react-oidc-context';
import { parseToken } from '../apis/tokenUtil';

export const Nav = () => {
  const auth = useAuth();
  const logout = () => {
    if (!auth.isLoading && auth.isAuthenticated) {
      auth.signoutRedirect({ id_token_hint: auth.user?.id_token }).then();
    }
  };
  return (
    <div style={{display: 'inline-block'}}>
      {auth.isAuthenticated &&
        <>
          <GoAAppHeaderMenu heading={parseToken(auth.user?.id_token as string)?.email} leadingIcon="person-circle">
          {
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a onClick={logout}>Log out</a> 
          }
          </GoAAppHeaderMenu>
        </>
      }
    </div>
  )
};

